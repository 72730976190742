import { ImageSharpFluid } from 'helpers/definitions';
import React from 'react';
import Img from 'gatsby-image';
import * as Styled from './styles';

interface Props {
  title: string;
  logo: ImageSharpFluid;
  subtitle: string;
  position: string;
  content: React.ReactNode;
  startDate: string;
  endDate: string;
}

const Timeline: React.FC<Props> = ({ title, logo, subtitle, position, content, startDate, endDate }) => (
  <Styled.Timeline>
    <Styled.Point />
    <Styled.Details>
      <Styled.Date>
        {startDate} - {endDate}
      </Styled.Date>
      {logo ? <Styled.Logo>
        <Img fluid={logo} alt={title} />
      </Styled.Logo> : null}
      <Styled.Title>{title}</Styled.Title>
      {subtitle ? <Styled.Subtitle>by {subtitle}</Styled.Subtitle> : null}
      <Styled.Subtitle>{position}</Styled.Subtitle>
    </Styled.Details>
    <Styled.Content>{content}</Styled.Content>
  </Styled.Timeline>
);

export default Timeline;
